// see date formats here https://day.js.org/docs/en/display/format
// [h] - escaping characters
import {PLACEHOLDER, ROUND_OPTION} from './const';

export const LANG_SETTING = {
    ru: {
        DateText: `Лучшая цена доступна ${PLACEHOLDER.DateFormat}`,
        DateFormat: 'DD.MM.YYYY',
        MinLosText: `При бронировании от ${PLACEHOLDER.MinLos}${PLACEHOLDER.MinLosEnd} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `по тарифу «${PLACEHOLDER.Offer}»`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    en: {
        DateText: `Best rate available ${PLACEHOLDER.DateFormat}`,
        DateFormat: 'MM.DD.YYYY',
        MinLosText: `When booking from ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `on "${PLACEHOLDER.Offer}" special offer`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    am: {
        DateText: `Ամենաշահավետ արժեքը ${PLACEHOLDER.DateFormat}`,
        DateFormat: 'DD.MM.YYYY',
        MinLosText: `When booking from ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `on "${PLACEHOLDER.Offer}" special offer`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    }
};
